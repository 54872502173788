import React from "react";
import ProjectCard from "../components/ProjectCard";
import { projectList } from "../data/data";
const Projects = () => {
  return (
    <section
      className="px-5 flex flex-col pb-10 text-gray-800 pt-24"
      id="projects"
    >
      <h1 className="text-3xl sm:text-5xl font-semibold pb-5 sm:pb-10">
        Projects
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
        {projectList.map((project, index) => {
          return (
            <div key={index}>
              <ProjectCard project={project} />
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Projects;
