import React, { useRef } from "react";
import About from "../sections/About";
import Certifications from "../sections/Certifications";
import Contact from "../sections/Contact";
import { Helmet } from "react-helmet";
import Experience from "../sections/Experience";
import Footer from "../sections/Footer";
import Intro from "../sections/Intro";
import Projects from "../sections/Projects";
import Skills from "../sections/Skills";
import { seo } from "../data/data";
import ViewCount from "../components/ViewCount";
import NavButton from "../sections/Navbar";

const IndexPage = () => {
  const navWidthref: any = useRef();
  return (
    <div style={{ maxWidth: "1440px" }} className="mx-auto xl:px-16">
      {seo && (
        <Helmet>
          <title>{seo.title}</title>
          <meta name="description" content={seo.description} />
          <meta name="keywords" content={seo.keywords} />
          <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
          <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8" />

          {/* <meta
          property="og:image"
          content="../../videos/thumbnail/configurator.jpg"
        /> */}
        </Helmet>
      )}
      <NavButton navRef={navWidthref} />
      <div id="home" ref={navWidthref} className="scroll-smooth">
        <Intro />
        <About />
        <Experience />
        <Projects />
        <Skills />
        <Certifications />
        <Contact />
        <Footer />
      </div>
    </div>
  );
};

export default IndexPage;
