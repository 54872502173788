import React from "react";
interface ProjectType {
  name: string;
  tech: string;
  info: string;
  live: string;
  repo: string;
}

const ProjectCard = ({ project }: { project: ProjectType }) => {
  const { name, tech, info, live, repo } = project;

  return (
    <div className="bg-blue-100 flex flex-col justify-between p-5 rounded-lg text-blue-900 text-opacity-75 lg:max-w-2xl hover:bg-white border border-transparent hover:border-blue-600 transform hover:scale-105 hover:shadow-xl transition-all duration-500 h-full">
      <div>
        <h1 className="font-light text-5xl pb-2 ">{name}</h1>
        <h2 className="font-medium text-xl pb-2 2xl:text-lg">{tech}</h2>
        <p className="2xl:text-lg">{info}</p>
      </div>
      <div className=" flex flex-col pt-5 text-center">
        <div className="flex justify-between mb-2">
          {live && (
            <a
              href={live}
              className="px-2 py-1 bg-blue-200 border border-blue-900 rounded text-blue-900 border-opacity-80 text-opacity-80 hover:bg-blue-100"
              target="_blank"
            >
              Live site
            </a>
          )}
          {repo && (
            <a
              href={repo}
              className="px-2 py-1 bg-blue-200 border border-blue-900 rounded text-blue-900 border-opacity-80 text-opacity-80 hover:bg-blue-100"
              target="_blank"
            >
              Github repo
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
