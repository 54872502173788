import React, { useEffect, useState } from "react";
import { IoIosArrowDropdown } from "react-icons/io";

interface ExperienceType {
  duration: string;
  role: string;
  company: string;
  responsibilities: string[];
  toolsAndTech: string[];
}

const ExperienceItem = ({
  experience,
  index,
  activeIndex,
  onIndexChange,
}: {
  experience: ExperienceType;
  index: number;
  activeIndex: number;
  onIndexChange: (newIndex: number) => void;
}) => {
  const { duration, role, company, responsibilities, toolsAndTech } =
    experience;
  return (
    <li className="mb-10 ml-4">
      <div className="absolute w-3 h-3 rounded-full mt-1.5 -left-1.5 bg-gray-700"></div>
      <time className="mb-1 text-base md:text-xl font-normal leading-none text-gray-400 dark:text-gray-500">
        {duration}
      </time>
      <div
        className="flex items-center transform md:w-max cursor-pointer"
        onClick={() => onIndexChange(index)}
      >
        <h3 className="mt-2 text-xl sm:text-2xl md:text-3xl font-medium text-left text-gray-700 mb-3 w-full md:w-auto">
          {role} - {company}
        </h3>
        <IoIosArrowDropdown
          className={`ml-5 w-8 h-8 transform transition ${
            index === activeIndex ? "rotate-180" : "rotate-0"
          }`}
        />
      </div>

      {index === activeIndex && (
        <ul>
          {responsibilities.map((res, index) => (
            <li
              key={index}
              className="text-blue-900 text-opacity-80 sm:text-lg md:text-xl pb-2 "
            >
              - {res}
            </li>
          ))}
          <li className="text-blue-900 text-opacity-80 sm:text-lg md:text-xl  pb-2 ">
            <span className="font-medium">Tools and Technologies: </span>
            {toolsAndTech.join(", ")}
          </li>
        </ul>
      )}
    </li>
  );
};

export default ExperienceItem;
