import React, { useState } from "react";
import ExperienceItem from "../components/ExperienceItem";
import { experiences } from "../data/data";
const Experience = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const onIndexChange = (newIndex: number) => {
    activeIndex === newIndex
      ? setActiveIndex(experiences.length)
      : setActiveIndex(newIndex);
  };
  return (
    <section
      id="experience"
      className="px-5  flex flex-col pb-10 text-gray-800 pt-24"
    >
      <h1 className="text-3xl sm:text-5xl font-semibold pb-5 sm:pb-10">
        Work Experience
      </h1>
      <ol className="relative border-l border-gray-600 ml-5 transform transition-all">
        {experiences.map((exp, index) => {
          return (
            <ExperienceItem
              key={index}
              experience={exp}
              index={index}
              activeIndex={activeIndex}
              onIndexChange={onIndexChange}
            />
          );
        })}
      </ol>
    </section>
  );
};

export default Experience;
