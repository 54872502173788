import React from "react";
import { myData } from "../data/data";
const Intro = () => {
  const { firstName, lastName, email } = myData;
  return (
    <div className="px-5 py-8 pt-24" id="intro">
      <div className="flex flex-col text-gray-800">
        <h1 className="text-4xl sm:text-6xl md:text-7xl tracking-wider font-lato font-semibold">
          {firstName} <span className="font-light">{lastName}</span>
        </h1>
        <a
          href={`mailto:${email}`}
          className="text-xl sm:text-2xl tracking-wider text-gray-400 pt-2"
          target="_blank"
        >
          {email}
        </a>
        <p className="text-lg sm:text-3xl md:text-5xl md:pt-10 font-light pt-4">
          Hi! Welcome to my portfolio
        </p>
      </div>
    </div>
  );
};

export default Intro;
