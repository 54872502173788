import React, { useEffect, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaArrowRight } from "react-icons/fa";
import { myData } from "../data/data";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";

const Navbar = ({ navRef }: { navRef: any }) => {
  const [navWidth, setNavWidth] = useState<number | undefined>(undefined);
  const btns = ["Experience", "Projects", "Skills", "Contact"];
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { firstName, lastName } = myData;
  const initials = (firstName[0] + lastName[0]).toLowerCase();
  useEffect(() => {
    if (navRef) {
      setNavWidth(navRef.current.getBoundingClientRect().width);
    }
  }, []);

  const toggleDrawer = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  const handleClick = (btn: string, isMobile?: boolean) => {
    isMobile && toggleDrawer();
    document
      .getElementById(btn.toLocaleLowerCase().replace(" ", ""))
      ?.scrollIntoView({
        behavior: "smooth",
      });
  };
  return (
    <div className="relative w-full">
      <div className="fixed z-50 w-full">
        <div
          className="navbar bg-blue-100 rounded-b-lg w-full border border-blue-200 px-5"
          style={navWidth ? { maxWidth: navWidth } : { maxWidth: "1440px" }}
        >
          <div
            onClick={() => handleClick("home")}
            className="flex-1 font-bold text-4xl"
          >
            {initials}.
          </div>
          <div className="md:flex justify-between hidden xl:w-2/5 md:w-3/5 items-center">
            {btns.map((btn, index) => (
              <div
                key={index}
                className="flex font-semibold text-2xl transform transition-transform hover:scale-105"
                onClick={() => handleClick(btn)}
              >
                {btn}
              </div>
            ))}
          </div>

          <div className=" md:hidden">
            <div
              onClick={toggleDrawer}
              className="swap swap-rotate"
              style={{ zIndex: "150" }}
            >
              <input checked={isMenuOpen} type="checkbox" />
              <GiHamburgerMenu className="w-7 h-7 swap-off fill-current" />
              <FaArrowRight className="w-7 h-7 swap-on fill-current" />
            </div>
            <Drawer
              open={isMenuOpen}
              onClose={toggleDrawer}
              direction="right"
              style={{
                background: "#DBEAFE",
                borderRadius: "10px 0 0 10px",
              }}
            >
              <div className="flex flex-col justify-center items-center h-4/5">
                {btns.map((btn, index) => (
                  <div
                    key={index}
                    className="flex font-semibold text-3xl transform transition-transform mt-20 hover:scale-105"
                    onClick={() => handleClick(btn, true)}
                  >
                    {btn}
                  </div>
                ))}
              </div>
            </Drawer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
