export const myData = {
  firstName: "Sandeep",
  lastName: "Gumaste",
  email: "sandeepgumaste10@gmail.com",
  github: "https://github.com/SandeepGumaste",
  freecodeCamp: "https://www.freecodecamp.org/sandeepp",
  frontEndMentor: "https://www.frontendmentor.io/profile/SandeepGumaste",
};

export const projectList = [
  {
    name: "Support Desk",
    tech: "MERN | Redux-Thunk | JWT",
    info: "A web app where you can ask for help to a tech support by creating tickets and also track their status",
    live: "https://sg-support-desk.herokuapp.com/",
    repo: "https://github.com/SandeepGumaste/MERN-Support-Desk",
  },
  {
    name: "House Marketplace",
    tech: "React | CSS | Firebase| OAuth",
    info: "A web app where you can sell/rent a house",
    live: "https://sg-house-marketplace.vercel.app/",
    repo: "https://github.com/SandeepGumaste/house-marketplace",
  },
  {
    name: "Github Profile Finder",
    tech: "React | TailwindCSS | DaisyUI",
    info: "Finds a github user's profile and displays user stats",
    live: "https://sg-github-finder.vercel.app/",
    repo: "https://github.com/SandeepGumaste/Github-Profile-Finder",
  },
  {
    name: "Maze Game",
    tech: "Javascript | MeteorJS | Depth first search Algorithm",
    info: "A game where you'll hav to navigate the ball and reach the goal to win",
    live: "https://sg-maze-game.netlify.app/",
    repo: "https://github.com/SandeepGumaste/Maze-Game",
  },
  {
    name: "Country Database",
    tech: "React | Tailwind CSS | REST api",
    info: "Find any country and get its stats",
    live: "https://rest-countries-sandeepgumaste.netlify.app",
    repo: "https://github.com/SandeepGumaste/react-REST-Countries",
  },
  {
    name: "Inshorts Clone",
    tech: "MERN stack | Material UI",
    info: "Inshorts clone app with infinite scroll feature",
    live: "https://inshorts-clone-app.herokuapp.com/",
    repo: "https://github.com/SandeepGumaste/inshorts-clone-app",
  },
  {
    name: "Todo list",
    tech: "React | Tailwind CSS",
    info: "Minimalist themed todo list with react hooks and local storage",
    live: "https://sg-react-todo.netlify.app/",
    repo: "https://github.com/SandeepGumaste/react-todo-list/tree/main/todo-note",
  },
  {
    name: "Calculator",
    tech: "HTML | Tailwind CSS | Javascript ",
    info: "A minimalist calculator with 3 different theme options",
    live: "https://sg-frontendmentor.netlify.app/calculator-app-main/dist/index.html",
    repo: "https://github.com/SandeepGumaste/Frontend-mentor-challenges/tree/main/calculator-app-main",
  },
];

export const skills = [
  { id: 16, name: " HTML & (S)CSS" },
  { id: 12, name: "Javascript(ES6+)" },
  { id: 14, name: "Typescript" },
  { id: 13, name: "React" },
  { id: 19, name: "Figma" },
  { id: 20, name: "Node" },
  { id: 21, name: "Express" },
];

export const certificationList = [
  {
    name: "React 2022",
    link: "https://www.udemy.com/certificate/UC-1decc9e2-c35c-4e52-80d7-569a8043a09b/",
  },
  {
    name: "JavaScript Algorithms and Data Structures",
    link: "https://www.freecodecamp.org/certification/sandeepp/javascript-algorithms-and-data-structures",
  },
  {
    name: "Responsive Web Design",
    link: "https://www.freecodecamp.org/certification/sandeepp/responsive-web-design",
  },
  {
    name: "Scientific Computing with Python",
    link: "https://www.freecodecamp.org/certification/sandeepp/scientific-computing-with-python-v7",
  },
];

export const experiences = [
  {
    duration: "November 2021 - Present",
    role: "Frontend Engineer",
    company: "HelloAR",
    responsibilities: [
      "Translated UI/UX mockups to responsive web sites and apps.",
      "Converted design system to reusable react components with micro interactions.",
      "Built and maintained the frontends for several web and hybrid apps.",
      "Worked with the backend developers on integrating REST APIs with the frontend.",
    ],
    toolsAndTech: [
      "React.js",
      "Next.js",
      "Gatsby.js",
      "Redux",
      "TailwindCSS",
      "Bootstrap",
      "REST APIs",
      "Webpack",
      "Capacitor.js",
      "Git",
    ],
  },
  {
    duration: "October 2018 - May 2020",
    role: "Associate CAD Engineer",
    company: "TE Connectivity",
    responsibilities: [
      "Designed housings and connectors for various automotive parts.",
      "Created high quality image renderings of CAD models using Keyshot.",
      "Reverse engineered some old products and developed new and improved CAD drawings.",
      "Product data management of BOMs.",
    ],
    toolsAndTech: [
      "Creo",
      "Fusion 360",
      "AutoCAD",
      "Keyshot",
      "Sheet metal development",
      "Adobe illustrator",
      "PDM Link",
    ],
  },
  {
    duration: "October 2017 - October 2018",
    role: "CAD Instructor",
    company: "CADEM Innovations",
    responsibilities: [
      "Taught CAD Basics to advanced modeling topics and 3d printing",
    ],
    toolsAndTech: ["Creo", "Fusion 360", "AutoCAD", "3d printing"],
  },
];

export const seo = {
  keywords:
    "sandeep_gumaste, Sandeep,Gumaste,Frontend Developer,Sandeep Gumaste Portfolio",
  description: "Hi there! I'm Sandeep Gumaste. Have a look at my portfolio.",
  title: "Sandeep Gumaste",
};
