import React from "react";
import { certificationList } from "../data/data";

const Certifications = () => {
  return (
    <section className="px-5 pb-10 text-gray-800 pt-24">
      <h1 className="text-3xl sm:text-5xl font-semibold pb-5">
        Certifications
      </h1>
      <ul>
        {certificationList.map(({ name, link }, index) => {
          return (
            <li className="pb-3" key={index}>
              <a
                href={link}
                className="text-blue-900 text-opacity-80 font-semibold text-2xl hover:opacity-80"
                target="_blank"
              >
                {name}
              </a>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default Certifications;
