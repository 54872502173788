import React from "react";
import { SiTailwindcss, SiGatsby } from "react-icons/si";
import ViewCount from "../components/ViewCount";

const Footer = () => {
  return (
    <section className="px-5 py-2 md:text-2xl bg-blue-100 text-blue-900 text-opacity-75 rounded-t-lg mt-24">
      <div className="flex items-center justify-center ">
        Built using &nbsp; <SiGatsby /> &nbsp; + &nbsp; <SiTailwindcss /> &nbsp;
        by Sandeep Gumaste
      </div>
      {/* <ViewCount /> */}
    </section>
  );
};

export default Footer;
