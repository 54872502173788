import React from "react";

const About = () => {
  return (
    <section className="px-5 pb-10 text-gray-800">
      <div className="flex flex-col ">
        {/* <h1 className="text-3xl sm:text-5xl font-semibold pb-5">About Me</h1> */}
        <p
          className="text-xl sm:text-2xl md:text-3xl font-light tracking-wide"
          style={{ lineHeight: "2.5rem" }}
        >
          I'm a passionate self taught web developer.
          <br />
          As a curious person, I love learning new things and solving problems
          which is why I have always been interested in making/building things
          that can help people. Before switching to web development, I used to
          work as a CAD Engineer. Now I love developing elegant, user-friendly
          and responsive frontends.
          <br />
        </p>
      </div>
    </section>
  );
};

export default About;
