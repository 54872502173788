import React from "react";
import { FaGithub, FaFreeCodeCamp, FaHackerrank } from "react-icons/fa";
import { SiFrontendmentor } from "react-icons/si";
import { myData } from "../data/data";

const Contact = () => {
  return (
    <section
      id="contact"
      className="px-5 flex flex-col pb-10 text-gray-800 pt-24"
    >
      <h1 className="text-3xl sm:text-5xl font-semibold pb-5">Get in touch</h1>
      <div>
        <h2 className="text-xl sm:text-2xl md:text-3xl">
          Ready to create something together?
        </h2>
        <a
          href={`mailto:${myData.email}`}
          className="text-blue-900 text-opacity-80 font-semibold text-2xl hover:opacity-80"
          target="_blank"
        >
          Let's chat
        </a>
        <h2 className="text-xl sm:text-2xl md:text-3xl font-semibold py-5">
          Find me elsewhere
        </h2>
        <div className=" flex text-6xl">
          <a
            href={myData.github}
            className="mr-5 hover:text-blue-900 hover:opacity-80"
            target="_blank"
          >
            <FaGithub />
          </a>
          <a
            href={myData.freecodeCamp}
            className="mr-5 hover:text-blue-900 hover:opacity-80"
            target="_blank"
          >
            <FaFreeCodeCamp />
          </a>
          <a
            href={myData.frontEndMentor}
            className=" hover:text-blue-900 hover:opacity-80"
          >
            <SiFrontendmentor />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Contact;
